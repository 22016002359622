/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
// import GravityForm from 'components/GravityForm'
// import ParseContent from 'components/shared/ParseContent'
import HeroLanding from 'components/elements/HeroLanding'
import { Service, QuickScan, Reference, HeadingLayout, Prides } from 'components/elements'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout landing="t" footerDescription={acf.footer.description}>
      <SEO yoast={yoast} path={path} />

      <HeroLanding
        fields={acf.banner}
      />

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <Service
            fields={acf.blok_1}
            direction="left"
            backgroundTitle={acf.blok_1.background_title}
          />

          <a id="quickscan" />
          
        </div>
      </div>

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_2.background_title}>
            <QuickScan
              fields={{ ...acf.blok_2, ...{ form: acf.form } }}
            />
          </HeadingLayout>
        </div>
      </div>

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_3.background_title} direction="right">
            <Reference
              fields={acf.blok_3}
            />
          </HeadingLayout>
        </div>
      </div>

      <div className="pt-lg-5 mt-5">
        <div className="pt-5">
          <Prides
            fields={acf.blok_4}
          />
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        form {
          gravity_form_id
        }

        blok_1 {
          background_title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        blok_2 {
          background_title
          description
        }

        blok_3 {
          background_title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        blok_4 {
          description
          items {
            description
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }

        footer {
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
