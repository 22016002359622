/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/ButtonDefault'
import maskImg from 'img/hero-mask.png'

const Container = styled(ImageBackground)`
  height: 540px;
  background-position: right;

  @media screen and (max-width: 992px) {
    height: auto;
  }

  &:after {
    width: 65% !important;
    left: 35% !important;
  }
`
const MaskContainer = styled(ImageBackground)`
  height: 540px;
  width: 54%;
  background-position: left;
  background-size: 100% 100%;
  color: white;
  padding-left: calc(50% - 512px);
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  position: absolute !important;

  @media screen and (max-width: 992px) {
    background: ${(props) => props.theme.color.face.main};
    position: relative !important;
    width: 100%;
    padding: 40px 90px 40px 90px;
    height: auto;
  }

  @media screen and (max-width: 576px) {
    padding: 40px 40px 40px 40px;
  }
`
const Heading = styled.div`
  width: 440px;
  color: ${props => props.theme.color.text.light};

  & h1 {
    font-weight: ${props => props.theme.font.weight.s};
    line-height: 50px;
    color: ${props => props.theme.color.text.light};
    /* font-size: ${props => props.theme.font.size['75']}; */

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size['35']};
      line-height: 40px;
      margin-bottom: 20px;
    }

    & strong, & b {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }
  
  @media screen and (max-width: 992px) {
    width: 100%;

    p {
      margin-bottom: 20px;
    }
  }
`
const ContainerContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export default function HeroLanding({
  fields,
  buttonText,
  buttonURL,
}) {
  return (
    <div className="position-relative">
      <Container
        src={fields.image}
        style={{ backgroundSize: 'cover', backgroundPosition: 'right' }}
        aboveFold
      >
        <MaskContainer
          src={maskImg}
          aboveFold
          style={{ backgroundSize: '100% 100%', backgroundPosition: 'left' }}
          className="mask-container"
        >
          <div className="d-lg-none d-block">
            <Heading>
              <ParseContent content={fields.description} />
            </Heading>
            {buttonText && (
              <Button to={buttonURL} className="my-5">
                <ParseContent content={buttonText} />
                {' '}
              </Button>
            )}
          </div>
        </MaskContainer>
      </Container>
      <ContainerContent className="d-lg-block d-none">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-5 h-100 d-flex align-items-center pl-lg-5">
              <div>
                <Heading>
                  <ParseContent content={fields.description} />
                </Heading>
                {buttonText && (
                  <Button to={buttonURL} className="my-5">
                    <ParseContent content={buttonText} />
                    {' '}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ContainerContent>
    </div>
  )
}
